import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const textVariants = cva("transition-colors text-white", {
  variants: {
    mdVariant: {
      giant: "md:text-[150px] md:leading-[150px]",
      secondGiant: "md:text-[120px] md:leading-[120px]",
      big: "md:text-[80px] md:leading-[88px]",
      h1: "md:text-[61px] md:leading-[80px]",
      h2: "md:text-[39px] md:leading-[52px]",
      h3: "md:text-[31px] md:leading-[40px]",
      t1: "md:text-[25px] md:leading-[32px]",
      t2: "md:text-[20px] md:leading-[28px]",
      b1: "md:text-[16px] md:leading-[20px]",
      b2: "md:text-[13px] md:leading-[16px]",
      btn1: "md:text-[25px] md:leading-[32px]",
      btn2: "md:text-[20px] md:leading-[28px]",
      btn3: "md:text-[16px] md:leading-[20px]",
      btn4: "md:text-[13px] md:leading-[16px]",
    },
    smVariant: {
      giant: "text-[150px] leading-[150px]",
      secondGiant: "text-[120px] leading-[120px]",
      big: "text-[80px] leading-[88px]",
      h1: "text-[61px] leading-[80px]",
      h2: "text-[39px] leading-[52px]",
      h3: "text-[31px] leading-[40px]",
      t1: "text-[25px] leading-[32px]",
      t2: "text-[20px] leading-[28px]",
      b1: "text-[16px] leading-[20px]",
      b2: "text-[13px] leading-[16px]",
      btn1: "text-[25px] leading-[32px]",
      btn2: "text-[20px] leading-[28px]",
      btn3: "text-[16px] leading-[20px]",
      btn4: "text-[13px] leading-[16px]",
    },
  },
  defaultVariants: {
    mdVariant: "b1",
    smVariant: "b1",
  },
});

export interface TextProps
  extends React.HTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof textVariants> {
  asChild?: boolean;
}

const Text = React.forwardRef<HTMLParagraphElement, TextProps>(
  ({ className, mdVariant, smVariant, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "p";
    return (
      <Comp
        className={cn(textVariants({ mdVariant, smVariant, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);

Text.displayName = "Text";

export { Text, textVariants };
