import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import IconSVG from "@/assets/images/icon.svg";
import { Button } from "./ui/button";
import { Text } from "./ui/text";
import { Link, useLocation, useRouter } from "@tanstack/react-router";
import { useWallet } from "@solana/wallet-adapter-react";
import ConnectWalletButton from "./ConnectWalletButton";
import { LogOut } from "lucide-react";
import { useReleaseStore } from "@/zustand/releaseSlice";

const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const { connected, publicKey, disconnect } = useWallet();
  const { isReleased, setIsReleased } = useReleaseStore();
  const router = useRouter();
  const isOnICOPage = router.state.location.pathname === "/ico";

  // Get the current path from useLocation
  const location = useLocation();
  const currentPath = location.href;

  const NAVBAR_BUTTONS = [
    { label: "Home", href: "/" },
    { label: "Swap", href: "/swap" },
    { label: "Staking", href: "/staking" },
    { label: "Reward Allocation", href: "/reward-allocation" },
    { label: "Portfolio", href: "/portfolio" },
    { label: "FAQ", href: "/faq" },
    { label: "Docs", href: "/docs" },
  ];

  const visibleButtons = isReleased ? NAVBAR_BUTTONS : [];

  // Scroll detection to add shadow
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={cn(
        "bg-transparent fixed top-0 z-50 w-full px-[20px] md:px-[104px] py-3 flex justify-between items-center",
        isScrolled ? "bg-opacity-50 shadow-md backdrop-blur-md" : ""
      )}
    >
      <Link to="/">
        <img src={IconSVG} alt="icon" />
      </Link>

      <div className="flex items-center gap-2">
        {visibleButtons.map((button, index) => (
          <Link to={button.href} key={index}>
            <Button variant={"whiteLink"}>
              <Text
                mdVariant={"btn3"}
                smVariant={"btn4"}
                className={cn(
                  "font-medium font-SFCompact hover:text-[#19FB9B] hover:underline",
                  currentPath === button.href ? "text-[#19FB9B]" : ""
                )}
              >
                {button.label}
              </Text>
            </Button>
          </Link>
        ))}

        {isReleased || isOnICOPage ? (
          <ConnectWalletButton className="h-8 w-[120px]">
            <Text
              mdVariant={"btn3"}
              smVariant={"btn4"}
              className="font-medium font-SFCompact text-[#12003A]"
            >
              {connected
                ? publicKey?.toString().slice(0, 10) + "..."
                : "Connect Wallet"}
            </Text>
          </ConnectWalletButton>
        ) : (
          <Link to={"/ico"}>
            <Button variant={"greenFilled"} className="h-8 w-[120px]">
              <Text
                mdVariant={"btn3"}
                smVariant={"btn4"}
                className="font-medium font-SFCompact text-[#12003A]"
              >
                Get $NVXC Now
              </Text>
            </Button>
          </Link>
        )}

        {connected && (
          <Button
            className="justify-center items-center flex flex-row gap-2 h-8 p-2 mx-4 rounded-sm"
            variant={"grayFilled"}
            onClick={disconnect}
          >
            <LogOut size={20} className="text-white" />

            <Text
              mdVariant={"btn3"}
              smVariant={"btn4"}
              className={cn("font-medium font-SFCompact hidden md:block")}
            >
              Disconnect
            </Text>
          </Button>
        )}

        {/* DEV - Release Page Button - hidden req by Calpa */}
        {/* {import.meta.env.DEV && (
          <Button
            className="justify-center items-center flex flex-row gap-2 h-8 p-2 mx-4 rounded-sm"
            variant={"grayFilled"}
            onClick={() => setIsReleased(!isReleased)}
          >
            <Text
              mdVariant={"btn4"}
              className={cn("font-medium font-SFCompact")}
            >
              Show {isReleased ? "PROD" : "DEV"}
            </Text>
          </Button>
        )} */}
      </div>
    </div>
  );
};

export default NavBar;
