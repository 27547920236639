import { createWithEqualityFn } from "zustand/traditional";

export type ReleaseState = {
  isReleased: boolean;
  setIsReleased: (isReleased: boolean) => void;
};

export const useReleaseStore = createWithEqualityFn<ReleaseState>((set) => ({
  isReleased: false,
  setIsReleased: (isReleased: boolean) => set({ isReleased }),
}));
