// import Footer from "@/components/Footer";
import Footer from "@/components/Footer";
import NavBar from "@/components/NavBar";
import { createRootRoute, Outlet } from "@tanstack/react-router";

export const Route = createRootRoute({
  component: () => (
    <div>
      <NavBar />
      <div className="min-h-screen">
        <Outlet />
      </div>
      <Footer />
    </div>
  ),
});
