import React from "react";
import { Button } from "./ui/button";
import { useWallet } from "@solana/wallet-adapter-react";

type ConnectWalletButtonProps = {
  className?: string;
  children?: React.ReactNode;
  onAction?: () => void; // Callback for the buy action
};

const ConnectWalletButton: React.FC<ConnectWalletButtonProps> = ({
  className,
  children,
  onAction,
}) => {
  const { select, connect, connected, wallets } = useWallet();

  const handleClick = async () => {
    if (!connected) {
      try {
        // Select the first wallet if none is selected
        if (wallets.length > 0) {
          select(wallets[0].adapter.name); // Select the first available wallet (e.g., Phantom)
        }
        await connect(); // Connect to the wallet
      } catch (error) {
        console.error("Wallet connection failed:", error);
      }
    } else if (onAction) {
      try {
        // Execute the action callback (e.g., buyWithSol or buyWithUsdt)
        onAction();
      } catch (error) {
        console.error("Action failed:", error);
      }
    }
  };

  return (
    <Button variant={"greenFilled"} className={className} onClick={handleClick}>
      {children}
    </Button>
  );
};

export default ConnectWalletButton;
