import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import "@/index.css";
import "@solana/wallet-adapter-react-ui/styles.css";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SolanaProvider from "./components/solana/SolanaProvider";

// Create a new router instance
const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  const client = new QueryClient();

  root.render(
    <StrictMode>
      <QueryClientProvider client={client}>
        <SolanaProvider>
          <WalletModalProvider>
            <RouterProvider router={router} />
          </WalletModalProvider>
        </SolanaProvider>
      </QueryClientProvider>
    </StrictMode>
  );
}
